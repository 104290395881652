import React, { useState } from 'react';
import './cancel-button.css'

const CancelButton = ({ onFinish }) => {
  const [showModal, setShowModal] = useState(false);

  const handleContinue = () => {
    setShowModal(true); // Abrir el modal
  };

  const handleCancelDenuncia = () => {
    onFinish(true); // Ejecutar la función onFinish para cancelar la denuncia
    setShowModal(false); // Cerrar el modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Cerrar el modal sin cancelar la denuncia
  };

  return (
    <>
      <button className="cancel-button size16" onClick={handleContinue}>
        Cancelar
      </button>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 className='h2-modal-text'>¿Estás seguro que quieres cancelar la denuncia?</h2>
            <div className="modal-buttons">
              <button className="cancel-button-modal" onClick={handleCancelDenuncia}>
                Si, Cancelar
              </button>
              <button className="continue-button" onClick={handleCloseModal}>
                Continuar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CancelButton;

