import React, { useEffect, useState } from 'react';
import { openDB } from 'idb';
import './MisDenuncias.css'

function MisDenuncias({ onFinish, reasons, animals }) {
  const [reports, setReports] = useState([]);
  const transformedAnimals = animals.map(item => ({
    value: item._id,
    label: item.name
  }));


  useEffect(() => {
    async function fetchReports() {
      // Open the database
      const request = await indexedDB.open('reportDB', 7);

      request.onsuccess = function(event) {
        const db = event.target.result;
        // Start a transaction and access the object store
        const tx = db.transaction('reports', 'readonly');
        const store = tx.objectStore('reports');

        // Retrieve all reports from the store
        const allReports = store.getAll();

        allReports.onsuccess = function(event) {
           
            const updatedReports = event.target.result.map(report => {
              // Replace animalId codes with labels
             
              const updatedAnimalIds = report.animalId.map(id => {
                const foundAnimal = transformedAnimals.find(animal => animal.value == id);
                return foundAnimal.label; // Replace with label or keep the original if not found
              });
            
              // Replace reasonId code with label
              const foundReason = reasons.find(reason => reason._id === report.reasonId);
              const updatedReasonId = foundReason ? foundReason.code : report.reasonId; // Replace with label or keep the original if not found
               
              return {
                ...report,
                animalId: updatedAnimalIds,
                reasonId: updatedReasonId,
              };
            });
            // Set the reports to state
            setReports(updatedReports);
          };
  
          allReports.onerror = function(event) {
            console.log("error al leer mis denuncias");
          };
          

          
        }

     
    
    }

    fetchReports();
  }, []); // Empty dependency array so this runs only once on mount

  return (
    <div className="form-denuncia flex-column">
      <p className='tituloMisDenuncias'> MIS DENUNCIAS</p>
      
      <ul className='contenedorDenuncias'>
        {reports.length > 0 ? (
          reports.map((report) => (
            <div className='containerDenunciaIndividual'>
              <p>
                <span className='MisDenunciasTitleItems'>Animal/es:</span> {report.animalId.length > 0 ? report.animalId.join(', ') : 'Animal/es desconocidos'}
              </p>
              <p>
                <span className='MisDenunciasTitleItems'>Causa:</span> {report.reasonId}
              </p>
              <p>
                <span className='MisDenunciasTitleItems'>Reportado a CECARA:</span> {report.reported !== 'false' ? 'SI' : 'NO'}
              </p>
              <p>
                <span className='MisDenunciasTitleItems'>Fecha:</span> {report.created ? report.created : ' --/--/---- '}
              </p>
              <p>
                <span className='MisDenunciasTitleItems'>Detalles:</span> {report.description}
              </p>
            </div>
          ))          
        ) : (
          <li>No hay reportes.</li>
        )}
      </ul>

      <button className="ss-button size16 denuncia-next" onClick={onFinish}>
        Volver a inicio
      </button>
    </div>
  );
}

export default MisDenuncias;
