import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import searchIcon from '../../assets/icons/search.svg';
import "./step3.css";
import StepTitle from '../step-title/Step-title';
import CancelButton from '../cancel-button/CancelButton';

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontFamily: 'Inter',
      fontSize: '1rem',
      border: '1px solid #DADADD', // Borde de 1px con color verde
      borderRadius: '16px', // Bordes redondeados
      boxShadow: state.isFocused ? '0 0 0 1px #DADADD' : null, // Sombra en foco
      minHeight: '48px',
      backgroundImage: `url(${searchIcon})`, // icono lupa 
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '10px center',
      paddingLeft: '32px', // Añadir espacio para el ícono
      '&:hover': {
        borderColor: '#DADADD', // Color de borde al pasar el mouse
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '1rem',
      fontFamily: 'Inter',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#f1f1f1' : null, // Color de fondo al seleccionar o enfocar
      color: state.isSelected ? 'white' : '#383838',
      fontWeight: '500',
      '&:hover': {
        backgroundColor: '#45a049', // Color de fondo al pasar el mouse sobre una opción
        color: 'white',
      },
      fontFamily: 'Inter',
      fontSize: '1rem',
      height: '32px',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#4CAF50', // Color de fondo de las opciones seleccionadas
      borderRadius: '5px', // Bordes redondeados de las opciones seleccionadas
      color: 'white',
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontWeight: '500',
      minHeight: '32px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white', // Color del texto de las opciones seleccionadas
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      display: 'flex',
      alignItems: 'center'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      color: 'white', // Color de la 'x' para eliminar las opciones seleccionadas
      '&:hover': {
        backgroundColor: '#45a049', // Color de fondo al pasar el mouse sobre la 'x'
        color: 'white',
      },
    }),
  };

function Step3({ formData, handleAnimalsChange, handleNextStep, handlePreviousStep, animals, onFinish }) {

    const [selectedOptions, setSelectedOptions] = useState([]);
    const transformedAnimals = animals.map(item => ({
      value: item._id,
      label: item.name
    }));
    const [options, setOptions] = useState(transformedAnimals);
    useEffect(() => {
        const animalsIdAux = selectedOptions.map(animal => animal.value);
        handleAnimalsChange(animalsIdAux);
    }, [selectedOptions]);

    const handleChangeAux = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };

    const customNoOptionsMessage = () => {
        return "No se encontraron opciones"; // Mensaje personalizado
    };


  return (
    <>
      <StepTitle text={"Que animal es..."} handlePreviousStep={handlePreviousStep} />
      <h2 className='h2-step3'>Indícanos que animal es</h2>
      <span className='ss-regular size14 align-center spam-step3'>Utiliza el buscador para encontrar el animal</span>
      <Select 
        className='search-animal'
        name="animalId"
        value={selectedOptions}
        onChange={handleChangeAux}
        options={options}
        placeholder="Busca el animal"
        isClearable
        isSearchable
        isMulti
        noOptionsMessage={customNoOptionsMessage}
        styles={customStyles}
      />
      <button className="ss-button size16 denuncia-next" onClick={handleNextStep}>Siguiente</button>
      <CancelButton onFinish={onFinish}/>
    </>
  );
}

export default Step3;
