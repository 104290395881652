import React, { useState, useEffect } from 'react';
import './../../SplashScreen.css';
import './Loading.css'; // Importar el archivo CSS


function Loading() {
  const frases = [
    "CECARA trabaja por la conservación de aves rapaces en Argentina.",
    "La biodiversidad es la base de la vida en la Tierra. Protéjala.",
    "Conservar las aves rapaces es proteger el equilibrio de los ecosistemas.",
    "El esfuerzo del CECARA ayuda a asegurar un futuro sostenible para las aves rapaces.",
    "El CECARA fomenta la educación ambiental para crear conciencia sobre la importancia de la conservación."
  ];

  const [fraseActual, setFraseActual] = useState(frases[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFraseActual(prevFrase => {
        const currentIndex = frases.indexOf(prevFrase);
        const nextIndex = (currentIndex + 1) % frases.length; // Para que rote continuamente
        return frases[nextIndex];
      });
    }, 2000); // Cambiar cada 2 segundos

    return () => clearInterval(intervalId); // Limpiar el intervalo cuando el componente se desmonta
  }, [frases]);

  return (

      <div className="splash-screen loading-overlay">
            <div className="loading-spinner"></div>
            <span className='ss-regular size16'>{fraseActual}</span>
        </div>
      
      
  );
};

export default Loading;
