import React from 'react';
import StepTitle from '../step-title/Step-title';
import CancelButton from '../cancel-button/CancelButton';
import './step5.css'

function Step5({ formData, handleChange, handleSubmit, handlePreviousStep, onFinish }) {
  return (
    <>
      <StepTitle text={"Escribe una descripción"} handlePreviousStep={handlePreviousStep} />
      <h2>Danos algo más de información describiendo la situación</h2>
      <span className='ss-regular size14 align-center span-step5'>Si no querés agregar nada, simplemente confirmá la denuncia para finalizar el proceso.</span>
          
      <textarea className='details' rows="6" name="description" value={formData.description} onChange={handleChange} placeholder="Detalles adicionales..." />
      <button className="ss-button size16 denuncia-next" onClick={handleSubmit}>Enviar denuncia</button>
      <CancelButton onFinish={onFinish}/>
    </>
  );
}

export default Step5;
