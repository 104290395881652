import React from 'react';
import {ReactComponent as FotoOk } from './ok.svg';
import "./step6.css";

function Step6({onFinish}) {
  return (
    <>
      <div className='general-container'>
        <FotoOk></FotoOk>
        <div className='text-container'>
          <h2 className='h2-step6'>¡Gracias por enviarnos tu denuncia!</h2>
          <p className='ss-regular size14 align-center'>Estaremos revisando la información enviada a la brevedad.</p>
        </div>
      </div>
      <button className="ss-button size16 denuncia-next" onClick={() => {onFinish()}}>Volver a inicio</button>
    </>
  );
}

export default Step6;
