// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
// Optionally import other services you might use, like Firestore, Storage, etc.


const firebaseConfig = {
    apiKey: "AIzaSyDwnf-YvEi0adUbWMrisEzgyOeH4oJQQps",
    authDomain: "silent-song.firebaseapp.com",
    projectId: "silent-song",
    storageBucket: "silent-song.appspot.com",
    messagingSenderId: "662855149898",
    appId: "1:662855149898:web:54b7aaaa54c8fbf60d39b3",
    measurementId: "G-X4SYBS9MR7"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();