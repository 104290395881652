import React from 'react';
import RadioButton from '../radio-button/RadioButton';
import StepTitle from '../step-title/Step-title';
import CancelButton from '../cancel-button/CancelButton';
    

function Step2({ formData, handleChange, handleNextStep, handlePreviousStep, handleNextStepSkip, onFinish }) {
  const handleContinue = () => {
    if (formData.knowAnimal === 'NO') {
      // Saltar al paso 4 si selecciona "NO" (Saltea un paso)
      handleNextStepSkip();  
    } else {
      // Avanzar al siguiente paso
      handleNextStep();  
    }
  };
  
  return (
    <>
      <StepTitle text={"Que animal es..."} handlePreviousStep={handlePreviousStep} /> 
      <h2>¿Sabés qué animal es?</h2>
      <div>
        <RadioButton
            label="Sí."
            name="knowAnimal"
            value="SI"
            checked={formData.knowAnimal === 'SI'}
            onChange={handleChange}
        />
        <RadioButton
            label="No, desconozco."
            name="knowAnimal"
            value="NO"
            checked={formData.knowAnimal === 'NO'}
            onChange={handleChange}
        />

      </div>
      <button className="ss-button size16 denuncia-next" onClick={handleContinue}>Siguiente</button>
      <CancelButton onFinish={onFinish}/>
    </>
  );
}

export default Step2;
