import React from 'react';
import './RadioButton.css';

const RadioButton = ({ label, name, value, checked, onChange }) => {
  return (
    <label className="custom-radio-button">
      <span className="radio-label">{label}</span>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="radio-input"
      />
      <span className="radio-checkmark"></span>
    </label>
  );
};

export default RadioButton;
