import React from 'react';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import './step-title.css';

const StepTitle = ({ text, handlePreviousStep }) => {
    return (
        <div className='div-container'>
            {handlePreviousStep !== undefined &&
            <img 
                src={arrowLeft} 
                alt={'Back Arrow'} 
                width={24} 
                height={24} 
                onClick={handlePreviousStep} 
            />}
            <p className="text">{text}</p>
        </div>
    );
};

export default StepTitle;