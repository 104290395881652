import React, { useRef, useState } from 'react';
import {ReactComponent as FotoIcon } from './foto.svg';
import StepTitle from '../step-title/Step-title';
import './step4.css';
import CancelButton from '../cancel-button/CancelButton';
import { formatImageToWebp } from '../../utils/imageFormatter';

function Step4({ formData, handlePhotoChange, handleNextStep, handlePreviousStep, onFinish }) {
    const [imageData, setImageData] = useState(null);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    const handleCapture = async (event) => {
      const file = event.target.files[0];
      if (file) {
          setLoading(true); 
          const reader = new FileReader();
          reader.onloadend = async () => {
              try {
                  const base64Image = reader.result;
                  const webpImage = await formatImageToWebp(base64Image);
                  setImageData(webpImage); // Establecer la imagen WebP
              } catch (error) {
                  console.error('Error al procesar la imagen. Intenta nuevamnete');
              } finally {
                  setLoading(false);
              }
          };
          reader.readAsDataURL(file);
      }
  };

      const handleClickTakePicture = () => {
        inputRef.current.click(); // Hace clic en el input al que se hace referencia
      };
    
  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <StepTitle text="Sube una foto" handlePreviousStep={() => { formData.knowAnimal === 'NO' ? handlePreviousStep(2) : handlePreviousStep(); }} />
      <h2>{!imageData ? "Toma una foto del animal y súbela" : "¿Quieres usar esta foto?"}</h2>
      {!imageData && <div className='container-svg-step-4'><FotoIcon /></div>}
      {imageData && (
        <>
          <span className='ss-regular size14 align-center black-cecara'>Asegúrate que la foto se vea nítida.</span>
          <div className='container-img-step-4'>
            <div className='container-img-foto'>
              <img src={imageData} alt="Captured" style={{ width: '100%' }} />
            </div>
          </div>
        </>
      )}

      <input
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleCapture}
          style={{ display: 'none' }}
          id="cameraInput"
          ref={inputRef}
      />
      
      <button className='photo-button size16 ss-medium black-cecara' onClick={handleClickTakePicture}>
          {!imageData ? "Sacar Foto" : "Tomar otra foto"}
      </button>

      <button className="ss-button size16 denuncia-next black-cecara" onClick={() => {handlePhotoChange(imageData, 'photoAnimal'); handleNextStep()}}>Siguiente</button>
      <CancelButton onFinish={onFinish} />
    </>
  );
}

export default Step4;