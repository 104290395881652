export const formatImageToWebp = async (imageSrc) => {
    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve, reject) => {
        image.onload = async () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const originalWidth = image.width;
            const originalHeight = image.height;
            let targetWidth, targetHeight;

            if (originalWidth > originalHeight) {
                // Imagen apaisada
                targetWidth = Math.min(1024, originalWidth);
                targetHeight = (originalHeight / originalWidth) * targetWidth;
            } else {
                // Imagen vertical
                targetHeight = Math.min(768, originalHeight);
                targetWidth = (originalWidth / originalHeight) * targetHeight;
            }
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            ctx.drawImage(image, 0, 0, targetWidth, targetHeight);
            //Convertir a formato WEBP
            let webpImage = canvas.toDataURL('image/webp');
            let base64Size = webpImage.length * (3 / 4); 

            if((base64Size / 1024) > 300) {
                const indexQuality = 300 / base64Size;
                webpImage = canvas.toDataURL('image/webp', indexQuality)
            };
            resolve(webpImage);
        };
        image.onerror = (error) => reject(new Error('Error al cargar la imagen'));
    });
};
