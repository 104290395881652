import React from 'react';
import { auth, googleProvider } from './firebase';
import { signInWithPopup, getAuth, signInAnonymously, signOut } from 'firebase/auth';

const Login = (isAppInstalled, deferredPrompt, handleInstallClick, onLogin) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleAnonymousLogin = () => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(async (userCredential) => {
        // Signed in successfully
        const user = userCredential.user;

        const token = user.accessToken;

        await fetch(`${apiUrl}/api/v2/users/login`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: token, fromBackend: false })
        }).then((response) => {
          // Ensure the response is fully received before redirecting
          if (response.ok) {
            // cookie setted.
            // Now redirect to the admin page
            return response.json();
          } else {
            console.log('Login failed:', response.status);
            // Step 3: If any error happens, log out from Firebase
            throw new Error('Server authentication failed');
          }
        }).then((data) => {
          console.log("el token obtenido es:", data.session_id);
          localStorage.setItem('session_id', data.session_id);
          onLogin(data.session_id);
        }).catch(error => {
          console.error('Error:', error);
           // Step 3: If any error happens, log out from Firebase
           throw new Error('Server authentication failed');
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error signing in anonymously:", errorCode, errorMessage);
          // Step 3: If any error happens, log out from Firebase
        signOut(auth);
        console.log("User logged out from Firebase due to server failure.");
      });
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      const token = result.user.accessToken;

      await fetch(`${apiUrl}/api/v2/users/login`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: token, fromBackend: false })
      }).then((response) => {
        // Ensure the response is fully received before redirecting
        if (response.ok) {
          return response.json();
          // cookie setted.
          // Now redirect to the admin page
        } else {
          console.log('Login failed:', response.status);
          // TODO: LOGOUT FROM FIREBASE AND DO NOT ENTER INTO THE APPLICATION.
          // Step 3: If any error happens, log out from Firebase
          throw new Error('Server authentication failed');
        }
      }).then((data) => {
        localStorage.setItem('session_id', data.session_id);
        onLogin(data.session_id);
      }).catch(error => {
        console.error('Error:', error);
        // TODO: LOGOUT FROM FIREBASE AND DO NOT ENTER INTO THE APPLICATION.
        // Step 3: If any error happens, log out from Firebase
        throw new Error('Server authentication failed');
      });
      // You can now use the token to communicate with your backend or just use the user object as needed.
    } catch (error) {
      // Handle Errors here.
      const errorMessage = error.message;
      console.error('Error during Google login:', errorMessage);
        // Step 3: If any error happens, log out from Firebase
      await signOut(auth);
      console.log("User logged out from Firebase due to server failure.");
    }
  };

  return (
    <div className="container login gap-16">
      <h1>Iniciar Sesión</h1>

      <span className='ss-regular size16 align-center'>Inicia sesión utilizando tu cuenta social</span>


      <button className='ss-google size16' onClick={handleGoogleLogin}>
        <i class="g-normal"></i>
        <legend>Continuar con Google</legend>
      </button>

      <span className='ss-regular size14 align-center'>No publicaremos en ninguna de tus cuentas sin preguntarte primero.</span>
      <div class="separator">
        <span class="line"></span>
        <span class="circle"></span>
        <span class="line"></span>
      </div>
      <button class="ss-button size16" onClick={handleAnonymousLogin}>Continuar como Invitado</button>

       {/*  Mostrar el botón solo si no está instalada y hay deferredPrompt */}
       {!isAppInstalled && deferredPrompt && (
          <button className="marginTopAuto" onClick={handleInstallClick} style={{ padding: '10px', fontSize: '16px' }}>
            Instalar aplicación
          </button>
        )}
    </div>
  );
};

export default Login;
