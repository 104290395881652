import React from 'react';
import RadioButton from '../radio-button/RadioButton';
import StepTitle from '../step-title/Step-title';
import CancelButton from '../cancel-button/CancelButton';


function Step1({ formData, handleChange, handleNextStep, onFinish, reasons  }) {
  return (
    <>
      <StepTitle text={"Motivo de mortandad"} />
      <h2>¿Cúal es el motivo de mortandad?</h2>
      <div>
        {
          reasons.map((option, index) => {
            return (
            <RadioButton
                key={option._id}
                label={option.code}
                name="reasonId"
                value={option._id}
                checked={formData.reasonId === option._id}
                onChange={handleChange}
            />)
          })
        }
        
      </div>
      <button className="ss-button size16 denuncia-next" onClick={handleNextStep}>Continuar</button>
      <CancelButton onFinish={onFinish}/>
    </>
  );
}

export default Step1;